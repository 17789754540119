/* Filter
-------------------------------------------------- */

.filter {
  background: $background-color;
  margin-bottom: $grid-gutter-width;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;

  @include breakpoint(sm) {
    flex-direction: row;
    font-size: $font-size-less;
  }
}

.filter > * {
  width: 100%;
  max-width: 400px;
}

.filter > *:not(:first-child) {
  margin-top: $module-padding-less;

  @include breakpoint(sm) {
    margin-left: $grid-gutter-width;
    margin-top: 0;
  }
}


.filter select {
  border-color: $primary-color;
}
