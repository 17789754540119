/* Module
-------------------------------------------------- */

.module {
  margin-top: $module-margin;
}

.module-padding {
  padding: $module-padding;
}

.module-padding-less {
  padding: $module-padding-less;
}

.module .wrapper > *:last-child,
.module .wrapper > *:last-child > *:last-child {
  margin-bottom: 0;
}

.module footer {
  margin-top: $module-padding;
}

/* Module Expanded
-------------------------------------------------- */

.expand-item.is-active {
  position: relative;
  animation: fadeIn .5s ease-out forwards;
}

.expand-item::before {
  content: none;
}

.expand-item-fallback {
  padding: 7rem 0;
  font-family: "Rajdhani", sans-serif;
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 300;
  letter-spacing: .1em;
}

.expand-item-fallback .h2-style {
  font-size: 1.7em;
  margin-bottom: 0.2em;
}
