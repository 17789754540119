// DOC: http://hugeinc.github.io/flexboxgrid-sass/

@import '../variables';
@import '../mixin/flex';
@import '../mixin/breakpoint';

// Set the number of columns you want to use on your layout.
$grid-grid-columns: 12 !default;

// Set the gutter between columns.
$grid-gutter-width: 40px !default;

// Set a margin for the container sides.
$grid-outer-margin: 20px !default;

// Set container sizes
$grid-max-width: 940px !default;
$grid-max-width-small: 600px !default;
$grid-max-width-wide: 1280px !default;

// Syntax: name size
$grid-breakpoints:
  xs 0,
  sm $screen-sm,
  md $screen-md,
  lg $screen-lg !default;

//
// -- Stop editing -- //
//

$gutter-compensation: $grid-gutter-width * .5 * -1;
$half-gutter-width: $grid-gutter-width * .5;

.wrapper {
  position: relative;
  box-sizing: content-box;
  display: block;
  margin: 0 auto;
  padding-left: $grid-outer-margin;
  padding-right: $grid-outer-margin;
  max-width: $grid-max-width;
}

.wrapper.small {
  max-width: $grid-max-width-small;
}

.wrapper.wide {
  max-width: $grid-max-width-wide;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-outer-margin;
  padding-left: $grid-outer-margin;
}

.row {
  box-sizing: border-box;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

.row.reverse {
  @include flex-direction(row-reverse);
}

.row.no-margin {
  margin-right: 0;
  margin-left: 0;
}

.row.no-margin [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row.grid {
  margin-top: -$grid-gutter-width;
}

.row.grid [class*="col-"] {
  margin-top: $grid-gutter-width;
}

.col.reverse {
  @include flex-direction(column-reverse);
}

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  @include flex-grow(0);
  @include flex-shrink(0);

  // we leave @include flex-basis(auto) out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

$name: xs;
.col-#{$name} {
  @include flexboxgrid-sass-col-common;
  @include flex-basis(auto);
}
@for $i from 1 through $grid-grid-columns {
  .col-#{$name}-#{$i} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(100% / $grid-grid-columns * $i);
    max-width: 100% / $grid-grid-columns * $i;
  }
}
@for $i from 0 through $grid-grid-columns {
  .col-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-col-common;
    @if $i == 0 {
      margin-left: 0;
    } @else {
      margin-left: 100% / $grid-grid-columns * $i;
    }
  }
}
.col-#{$name} {
  @include flex-grow(1);
  @include flex-basis(0);
  max-width: 100%;
}
.start-#{$name} {
  @include justify-content(flex-start);
  text-align: left;
}

.center-#{$name} {
  @include justify-content(center);
  text-align: center;
}

.end-#{$name} {
  @include justify-content(flex-end);
  text-align: right;
}

.center-#{$name} > *,
.end-#{$name} > * {
  text-align: left;
}

.top-#{$name} {
  @include align-items(flex-start);
}

.middle-#{$name} {
  @include align-items(center);
}

.bottom-#{$name} {
  @include align-items(flex-end);
}

.around-#{$name} {
  @include justify-content(space-around);
}

.between-#{$name} {
  @include justify-content(space-between);
}

.first-#{$name} {
  order: -1;
}

.last-#{$name} {
  order: 1;
}


@each $breakpoint in $grid-breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  @media only screen and (min-width: $size) {
    .col-#{$name} {
      @include flexboxgrid-sass-col-common;
      @include flex-basis(auto);
    }
    @for $i from 1 through $grid-grid-columns {
      .col-#{$name}-#{$i} {
        @include flexboxgrid-sass-col-common;
        @include flex-basis(100% / $grid-grid-columns * $i);
        max-width: 100% / $grid-grid-columns * $i;
      }
    }
    @for $i from 0 through $grid-grid-columns {
      .col-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-col-common;
        @if $i == 0 {
          margin-left: 0;
        } @else {
          margin-left: 100% / $grid-grid-columns * $i;
        }
      }
    }
    .col-#{$name} {
      @include flex-grow(1);
      @include flex-basis(0);
      max-width: 100%;
    }
    .start-#{$name} {
      @include justify-content(flex-start);
      text-align: left;
    }

    .center-#{$name} {
      @include justify-content(center);
      text-align: center;
    }

    .end-#{$name} {
      @include justify-content(flex-end);
      text-align: right;
    }

    .center-#{$name} > *,
    .end-#{$name} > * {
      text-align: left;
    }

    .top-#{$name} {
      @include align-items(flex-start);
    }

    .middle-#{$name} {
      @include align-items(center);
    }

    .bottom-#{$name} {
      @include align-items(flex-end);
    }

    .around-#{$name} {
      @include justify-content(space-around);
    }

    .between-#{$name} {
      @include justify-content(space-between);
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }
  }
}
