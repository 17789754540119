/* Icon
-------------------------------------------------- */

.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-bottom: none;
}

.icon svg {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.icon svg,
.icon path {
  fill: currentColor;
  stroke: currentColor;
}

.icon + .text {
  margin-left: .5em;
}
