/* Aspect ratio
-------------------------------------------------- */

[data-aspect-ratio] {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    height: 0;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

[data-aspect-ratio="16:9"]::before {
  padding-top: (9 / 16) * 100%;
}

[data-aspect-ratio="4:3"]::before {
  padding-top: (3 / 4) * 100%;
}

[data-aspect-ratio="2:1"]::before {
  padding-top: (1 / 2) * 100%;
}

[data-aspect-ratio="1:1"]::before {
  padding-top: 100%;
}
