/* Contact Teaser
-------------------------------------------------- */

.contact-teaser .image {
  position: relative;
  overflow: hidden;
  height: 500px;
}

.contact-teaser .image-wrapper {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.contact-teaser .image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
  max-width: none;
}

.contact-teaser .text {
  background: $background-color;
  position: relative;
  max-width: 880px;
  text-align: center;
  margin: -210px auto 0;
  padding-top: 70px;

  @include breakpoint(xs) {
    padding-left: 0;
    padding-right: 0;
  }
}

.address-departments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;

  @include breakpoint(sm) {
    flex-direction: row;
  }
}

.address-departments a[href*="mailto:"],
.address-departments a[href*="tel:"] {
  font-weight: 700;
  color: $primary-color;
  border: none;

  &:hover {
    color: $primary-color-hover;
  }
}

.address-departments > div {
  padding: 0 ($default-margin / 2);
}

.address-information a {
  display: block;
  margin: $default-margin auto;
  border: none;
  max-width: 130px;
  word-break: break-word;
  hyphens: auto;
  vertical-align: top;
  text-align: center;

  @include breakpoint(sm) {
    display: inline-block;
  }
}

.address-information a + a {
  @include breakpoint(sm) {
    margin-left: 1em;
  }
}

.address-information img {
  display: inline-block;
  margin-bottom: 1em;
  width: 54px;
  height: auto;
}
