/* Layout Helpers
-------------------------------------------------- */

.cf::after, .wrapper::after, .row::after, { content: ""; display: block; height: 0; overflow: hidden; clear: both; }
.hidden { display: none !important; visibility: hidden; }
.no-js .hidden { display: block; }

/* Non-semantic helper classes */
.block-center { display: block; float: none; margin: 0 auto; }
.clear-both { clear: both; }
.clear-left { clear: left; }
.clear-right { clear: right; }
.float-left { float: left; }
.float-right { float: right; }
.align-center { display: block; margin: 0 auto; text-align: center; }
.align-left { text-align: left; }
.align-right { text-align: right; }
.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
