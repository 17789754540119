*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  list-style: none;
}

article, aside, details, figcaption, figure,
footer, header, main, menu, nav, section, summary {
  /* Add correct display for IE 9- and some newer browsers */
  display: block;
}

/* Forms
-------------------------------------------------- */

input,
label,
select,
button,
textarea {
  background: none;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  line-height: 1.54;

  /* Browsers have different default form fonts */
  font-size: 1em;
  font-family: $font-family;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

/* Box Sizing Reset
-------------------------------------------------- */

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  box-sizing: content-box;
}

/* Button Controls
-------------------------------------------------- */

input[type=checkbox],
input[type=radio] {
  width: 13px;
  height: 13px;
}

/* Search Input
-------------------------------------------------- */

/* Make webkit render the search input like a normal text field */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-------------------------------------------------- */

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding:0;
  border:0;
  background:none;
}

/* Textarea
-------------------------------------------------- */

textarea {
  /* Move the label to the top */
  vertical-align:top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
-------------------------------------------------- */

select[multiple] {
  vertical-align: top;
}
