/* Button
-------------------------------------------------- */

.button {
  background: $primary-color;
  position: relative;
  overflow: hidden;
  display: inline-block;
  *overflow: visible;
  border: 0;
  border-bottom: 2px solid $primary-color;
  margin: 0;
  padding: .55em 1.35em .35em;
  font-family: $font-family;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2 !important;
  color: #fff;
  text-decoration: none !important;
  text-align: center;
  vertical-align: middle;
  word-wrap: normal;
  outline: 0;
  text-transform: none;
  cursor: pointer;
  -webkit-touch-callout: none;
  transition: all .35s ease;

  @include breakpoint(xs) {
    display: block;
  }
}

.button:hover,
.button:focus,
a:hover .button {
  background-color: $primary-color-hover;
  color: #fff;
  border-bottom: 2px solid $primary-color-hover;
}

.button .icon {
  margin: -.1em -.25em 0 .5em;
}

.button-secondary {
  background: transparent;
  color: $primary-color;
  padding-right: .4em;
  padding-left: .4em;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid currentColor;
}

.button-secondary:hover,
.button-secondary:focus,
a:hover .button-secondary {
  background-color: inherit;
  color: $primary-color-hover;
}
