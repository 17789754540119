@import '../variables';

/**
 * Breakpoint mixin
 *
 * $screen-xs wide and below
 * $screen-sm wide and up
 * $screen-md wide and up
 * $screen-lg wide and up
 *
 * Usage:
 * @include breakpoint(xs) { … }
 */

@mixin breakpoint($name) {
  @if $name == xs {
    @media (max-width: $screen-xs) { @content; }
  }

  @else if $name == sm {
    @media (min-width: $screen-sm) { @content; }
  }

  @else if $name == md {
    @media (min-width: $screen-md) { @content; }
  }

  @else if $name == lg {
    @media (min-width: $screen-lg) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}
