/* Header
-------------------------------------------------- */

#header {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: ($grid-outer-margin * 2) 0;
  z-index: 999;
}

.header-wrapper {
  height: $header-height;
  display: flex;
  flex: auto;
  justify-content: space-between;
  align-items: center;
}

#header .logo {
  position: relative;
  border: 0;
  width: 150px;
  height: $header-height;
  z-index: 1;
}

#header .logo img {
  width: auto;
  max-width: none;
  height: 100%;
}

.navigation {
  position: relative;
  min-height: calc(100vh - (#{$header-height} + #{$grid-outer-margin}) - 3.5rem);
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
  color: $color-lighten;
  text-align: center;
  display: none;
}

.is-open-menu .navigation {
  display: flex;
}

/* Header Menu Open
-------------------------------------------------- */

.is-open-menu,
.is-open-menu body {
  overflow: hidden !important;
}

.is-open-menu .open-menu-bg {
  background: $navigation-color;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 1;
  transition: background-color .35s ease;

  // Scrollable
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.open-menu-fadeout .open-menu-bg {
  transition-delay: .15s;
  background: transparent;
}

.open-menu-fadeout .menu,
.open-menu-fadeout .meta-menu {
  animation: fadeOut .15s ease-out forwards;
}

/* Header Menu
-------------------------------------------------- */

.menu {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  margin: 0;
  padding: 0;
  display: block;
}

.menu-item {
  display: block;
  margin: 1em 0;
  font-size: 2.1em;
  line-height: 1.2;
  font-weight: 300;
  letter-spacing: .1em;
}

.menu-item a {
  color: $primary-color;
}

.menu-item a:hover,
.menu-item a:focus,
.menu-item.is-active a {
  border-bottom: 1px solid currentColor;
}

/* Header Meta Menu
-------------------------------------------------- */

.menu {
  margin: auto;
  animation: fadeIn .5s ease-out forwards;
}

.meta-menu {
  position: relative;
  margin: 2rem auto auto;
  font-size: $font-size-less;
  animation: fadeIn .5s ease-out forwards .15s;
  opacity: 0;
}

.meta-item:not(.meta-item-language) {
  margin: 1em 0;
  font-size: 1.65em;
  line-height: 1.2;
  font-weight: 300;
  letter-spacing: .1em;
}

.meta-item a:hover,
.meta-item a:focus,
.meta-item.is-active a {
  border-bottom: 1px solid currentColor;
}

.meta-item-language {
  display: inline-block;
  margin: 4rem .25em 0;
}

.meta-item-language a {
  display: inline-block;
  border: 1px solid currentColor;
  padding: 1.15em 2.25em;
  font-size: .933em;
  transition: background-color .5s ease;
}

.meta-item-language a:hover,
.meta-item-language.is-active a {
  background-color: rgba(237, 175, 75, .1);
  color: $primary-color;
}

/* Header Open Menu Item
-------------------------------------------------- */

#open-menu {
  display: inline-block;
  color: #fff;
  border-bottom: none;
  width: 40px;
  height: 40px;
}

#open-menu:hover {
  color: $primary-color;
}

@media (hover: none) {
  html:not(.is-open-menu) #open-menu:hover {
    color: #fff;
  }
}

.burger {
  width: 100%;
  display: inline-block;
}

.burger div {
  background-color: currentColor;
  display: block;
  height: 2px;
  margin: 8px 0;
  opacity: .75;
  transform-origin: center left;
  transition: width .25s ease-out, transform .25s ease-out, opacity .15s ease-out;
}

.burger div:nth-child(2) {
  width: 75%;
}

.burger div:nth-child(3) {
  width: 50%;
}

.is-open-menu:not(.open-menu-fadeout) .burger div:nth-child(1) {
  width: 40px;
  transform: rotate(45deg);
}

.is-open-menu:not(.open-menu-fadeout) .burger div:nth-child(2) {
  transform: rotate(-45deg) translate(-13px, 13px);
  transform-origin: top bottom;
  width: 40px;
}

.is-open-menu:not(.open-menu-fadeout) .burger div:nth-child(3) {
  opacity: 0;
}
