/* Anchor Navigation
-------------------------------------------------- */

.anchor-navigation {
  text-align: center;
}

.anchor-navigation .scrollable {
  white-space: nowrap;
  margin: 0 -$grid-outer-margin;
  padding: 0 $grid-outer-margin;
}

.sticky-element .scrollable {
  margin-top: 0;
}

@media screen and(min-width: 749px) {
  .sticky-element.pinned {
    background: $background-color;
    position: fixed;
    margin: 0;
    top: 0;
    right: 0;
    left: 0;
    width: auto !important;
    z-index: 999;
  }
}

.anchor-navigation nav::before,
.anchor-navigation nav::after {
  content: "";
  background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: $grid-outer-margin;
  z-index: 1;
}

.anchor-navigation nav::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  left: auto;
  right: 0;
}

.anchor-navigation li {
  display: inline-block;
}

.anchor-navigation a {
  position: relative;
  overflow: hidden;
  display: inline-block;
  *overflow: visible;
  border: 0;
  margin: 0;
  padding: .5em 1.35em .4em;
  font-family: "Rajdhani", sans-serif;

  font-size: 25px;
  line-height: 1.2 !important;
  color: #000;
  text-decoration: none !important;
  text-align: center;
  vertical-align: middle;
  word-wrap: normal;
  outline: 0;
  cursor: pointer;
  -webkit-touch-callout: none;
  transition: all .35s ease;
}

.anchor-navigation a:hover,
.anchor-navigation a:focus,
.anchor-navigation .is-active a {
  background: $primary-color;
  color: #fff;
}
