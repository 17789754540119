/* Typography
-------------------------------------------------- */

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  line-height: 1.34;
  font-weight: 700;
  margin: 10px auto;
  transition: color .35s ease;
  word-break: break-word;
  hyphens: auto;
}

h1 {
  font-family: $font-family-secondary;
  margin: 0 auto $default-margin;
  color: #fff;
  font-size: 40px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;

  @include breakpoint(sm) {
    font-size: 70px;
  }
}

h2,
.h2-style {
  font-family: $font-family-secondary;
  margin: 10px auto 30px;
  color: #292929;
  font-size: 35px;
  line-height: 1.2;
  font-weight: 300;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;

  @include breakpoint(sm) {
    font-size: 50px;
  }
}

h3 {
  margin: 10px auto 0;
  font-size: 21px;
  font-weight: 400;
  text-align: center;
}

h4 {
  font-size: 18px;
}

h5, h6 {
  font-size: 1rem;
  color: #7B7B7A;
  margin-bottom: .25em;
}

.subline {
  font-size: 20px;
  letter-spacing: .1em;
  text-transform: uppercase;
}

p, ul, ol, figure, pre, address {
  font-size: inherit;
  font-style: normal;
  line-height: inherit;
  margin: 0 0 $default-margin;
}

b, strong {
  font-weight: 700;
}

a {
  color: inherit;
  border-bottom: 1px solid currentColor;
  text-decoration: none;
}

a:hover {
  color: $text-link;
  border-bottom-width: 0;
}

// Unordered list
ul {
  list-style: none;
  margin-bottom: $default-margin;
  padding: 0;
}

ul li {
  padding-left: 1.75em;
}

ul li::before {
  content: "•";
  display: inline-block;
  margin-left: -1.25em;
  width: 1.25em;
}

// Ordered list
ol {
  margin-bottom: $default-margin;
  counter-reset: item;
}

ol li:before {
  content: counter(item) ".";
  counter-increment: item;
  position: absolute;
  margin: 0 0 0 -1.35em;
}

ol li li {
  padding-left: 1em;
}

// Definition list
dl {
  overflow: hidden;
  margin-bottom: $default-margin;
}
dl dt {
  font-weight: 700;
  width: 170px;
  float: left;
  clear: both;
  margin-right: -170px;
}

dl dd {
  float: left;
  padding-left: 190px;
}

dl dt:not(:first-of-type),
dl dd:not(:first-of-type) {
  margin-top: 1em;
}


// Navigation
nav {
  font-family: $font-family-secondary;
}

nav a,
a[href^="tel:"],
a[href^="fax:"] {
  border-bottom: none;
}

nav ul,
nav ul li,
nav ul li::before {
  content: none;
  margin: 0;
  padding: 0;
  list-style: none;
}
