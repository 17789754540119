/* Global Teaser Bar
-------------------------------------------------- */

.global-teaser-bar {
  margin-bottom: calc(-#{$module-margin} + 1px);
}

.global-teaser-bar ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include breakpoint(sm) {
    flex-direction: row;
  }

  @include breakpoint(md) {
    flex-wrap: nowrap;
  }
}

.global-teaser-bar li {
  display: inline-block;
  padding-top: 1px;

  @include breakpoint(sm) {
    flex-basis: 50%;
    width: 50%;

    &:nth-child(3) {
      flex-basis: 100%;
      width: 100%;
    }
  }

  @include breakpoint(md) {
    flex-basis: 100%;
    width: 33.3333333333% !important;
  }
}

.global-teaser-bar li + li {
  @include breakpoint(md) {
    padding-left: 1px;
  }
}

.global-teaser-bar li:nth-child(2) {
  @include breakpoint(sm) {
    padding-left: 1px;
  }
}

.global-teaser-bar a {
  background-color: $navigation-color;
  overflow: hidden;
  display: block;
  height: 100%;
  color: #fff;
}

.global-teaser-bar a:hover {
  color: $primary-color;
}

.global-teaser-bar a > div {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5rem $grid-outer-margin;
  height: 100%;
  align-items: center;

  @include breakpoint(md) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.global-teaser-bar div > b {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform .5s ease;
}

.global-teaser-bar a:hover div > b {
  transform: scale(1.15);
}

.global-teaser-bar a .text {
  margin: auto;
  text-align: center;
}

.global-teaser-bar a h3 {
  position: relative;
  font-family: "Rajdhani", sans-serif;
  font-size: 35px;
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: .1em;
  text-transform: uppercase;
  margin: 0 0 $default-margin;
  color: currentColor;
}
