/* Stage
-------------------------------------------------- */

#stage {
  background-color: #1D1D1D;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
  height: 95vh;
  min-height: 580px;

  @include breakpoint(md) {
    min-height: 630px;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    background-attachment: inherit;
  }
}

#stage .wrapper,
#stage .row {
  height: 100%;
}

#stage .row {
  padding-top: 140px;
  padding-bottom: 70px;
}

#stage .stage-teaser {
  display: block;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
}

#stage .stage-teaser h1 {
  margin-top: 0;
  margin-bottom: 0;
}

#stage .stage-teaser p {
  margin: 1em 0;
}

#stage .stage-teaser .button {
  margin: .5rem 0 0;
}

#stage .scrollindicator {
  position: absolute;
  bottom: 8vh;
  left: 50%;
  padding-top: 10%;
}

#stage .scrollindicator img {
  display: inline-block;
}

/* Stage Minimal
-------------------------------------------------- */

#stage.stage-minimal {
  min-height: auto;
  height: auto;
}

#stage.stage-minimal .wrapper,
#stage.stage-minimal .row {
  min-height: 50vh;
}

#stage.stage-minimal .back-link {
  position: absolute;
  bottom: $grid-outer-margin;
  left: $grid-outer-margin;
  color: $primary-color;
  text-transform: uppercase;
  border: none;
}

#stage.stage-minimal .back-link .text {
  border-bottom: 1px solid transparent;
}

#stage.stage-minimal .back-link:hover .text {
  border-bottom-color: currentColor;
}

// make the SVG-Pic clickable
#stage.stage-minimal .back-link:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

a.back-link {
  position: relative;
  display: inline-block;
}
