/* Client Quote
-------------------------------------------------- */

.client-quote .wrapper {

  @include breakpoint(md) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.client-quote .image {
  position: relative;
  width: 220px;
  flex: 0 0 220px;
  margin: 0 auto;
}


.client-quote .image::before {
  content: "”";
  display: inline-block;
  position: absolute;
  top: .49em;
  left: -.1em;
  font-size: 14rem;
  font-weight: 400;
  line-height: 0;
  color: $primary-color;
}

.client-quote .text {
  padding-left: $grid-gutter-width / 2;
  font-weight: 400;

  @include breakpoint(md) {
    padding-left: $grid-gutter-width;
  }
}

.client-quote blockquote {
  margin: $default-margin 0;
  letter-spacing: .1em;
}

.client-quote .citation {
  text-align: center;
  color: $primary-color;
}
