/* Form & Input Fields
-------------------------------------------------- */

form {
  clear: both;
  width: 100%;
}

fieldset {
  margin: $default-margin auto;
  padding: 0;
}

fieldset:first-child {
  margin-top: 0;
}

fieldset:last-of-type {
  margin-bottom: 0;
}

fieldset,
.submit {
  margin-left: auto;
  margin-right: auto;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
optgroup,
select,
textarea {
  border: none;
  display: inline-block;
  color: inherit;
  font: inherit;
  margin: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

::-webkit-input-placeholder {
  color: $color;
  opacity: 1 !important;
}

:-moz-placeholder {
  color: $color;
  opacity: 1 !important;
}

::-moz-placeholder {
  color: $color;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  color: $color;
  opacity: 1 !important;
}

.input {
  margin: ($default-margin / 2) 0;
}

label,
.label {
  display: block;
  padding: .5em 0;
  font-size: .9333em;
  font-weight: 300;
}

label {
  cursor: pointer;
}

input[type="text"],
input[type="email"],
textarea,
select {
  background: $background-color-input;
  border: 2px solid $border-color-input;
  width: 100%;
  font-size: 1rem;
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
  padding: 1rem .85rem .8rem;
  color: #000;
  vertical-align: baseline;
  line-height: 1.1;
  border-radius: 0;
  box-sizing: border-box;

  &:hover,
  &:focus {
    border: 2px solid $primary-color;
  }
}

input[type="file"] {
  width: 100%;
  border: 0;
}

textarea {
  resize: none;
  height: 150px;
  line-height: 1.54;
}

input[disabled] {
  cursor: not-allowed;
}

input[required]:invalid {
  box-shadow: none;
}

::-webkit-validation-bubble-message {
  padding: 1rem;
}

.checkbox,
.radio {
  position: relative;
  padding-left: 25px;
}

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  position: absolute;
  margin: 0 0 0 -25px;
}

.radio-inline,
.checkbox-inline {
  padding-left: 0;
}

.radio-inline label,
.checkbox-inline label {
  display: inline-block;
  margin-top: 0;
  margin-right: 25px;
  padding: 0;
}

.radio-inline input[type="radio"],
.checkbox-inline input[type="checkbox"] {
  position: relative;
  margin: 0 .5em 0 0;
}

form footer.submit {
  margin-top: $default-margin / 2;
}

/* Select field
-------------------------------------------------- */

.select {
  background: $background-color-input;
  position: relative;
  width: 100%;
  height: 3em;
  display: block;

  select {
    background: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: .2rem 1.75rem 0 .85rem;
    height: 100%;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 2px solid $border-color-input;
    color: $color;
    white-space: nowrap;

    // Disable default styling on ff
    -moz-appearance: none;

    // Disable ugly ass outline on firefox
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    // Disable default styling on webkit browsers
    -webkit-appearance: none;

    // Disable default arrow on IE 11+
    &::-ms-expand {
      display: none;
    }

    &:hover,
    &:focus {
      border-color: $primary-color;
      color: $color;
    }

    @media (hover: none) {
      &:hover,
      &:focus {
        color: $color;
      }
    }
  }

  &::before {
    content: "";
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    position: absolute;
    top: 2px;
    right: 1.75em;
    bottom: 2px;
    width: 1.5em;
    pointer-events: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: .85em;
    pointer-events: none;
    transform: translateY(-50%);
  }

  &:hover .icon,
  select:focus + .icon {
    color: $primary-color;
  }

  @media (hover: none) {
    &:hover .icon,
    select:focus + .icon {
      color: #000;
    }
  }
}
label.checkbox {
  input[type="checkbox"] {
    position: relative;
  }
}
// IE 9 only
@media all and (min-width:0\0) and (min-resolution:.001dpcm){
  .select {
    select {
      padding-right: 0;
    }

    &::after, &::before {
      display: none;
    }
  }
}

