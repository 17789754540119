/* Carousel
-------------------------------------------------- */

.carousel {
  position: relative;
}

.carousel-wrapper {
  background: $background-color;
  position: relative;
  overflow: hidden;
}

.carousel .wrap {
  white-space: nowrap; /* fallback */
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  user-select: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.carousel .animate {
  transition: all .4s ease;
}

.carousel.is-disabled .wrap {
  transform: none !important;
}

.carousel .wrap > * {
  display: inline-block; /* fallback */
  vertical-align: top; /* fallback */
  position: relative; /* fallback */
  width: 100%; /* fallback */
  height: 100%;
  flex: 1 0 100%;
  padding: 0;
  white-space: normal;
}

.carousel figure,
.carousel img {
  pointer-events: none;
}

.carousel-buttons {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.carousel-buttons button {
  background: transparent;
  position: absolute;
  top: 50%;
  left: $grid-outer-margin;
  padding: 0;
  color: $color;
  outline: none;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
  height: 55px;
  pointer-events: auto;

  @include breakpoint(md) {
    height: 80px;
  }
}

.carousel .next {
  left: auto;
  right: $grid-outer-margin;
}

.carousel-buttons button:hover {
  color: #000;
}

.carousel-buttons button .icon {
  top: 0;
  width: auto;
  height: 100%;
  pointer-events: none;
}

.carousel-nav {
  margin: 0 0 (-$module-margin) 0;
  padding: $grid-outer-margin;
  line-height: 0;
  text-align: center;
  white-space: nowrap;
  transform: translateY(calc(-50% + 1em));
}

.carousel-nav li {
  display: inline-block;
  width: 35vw;
  max-width: 250px;
  line-height: $line-height;
  cursor: pointer;
}

.carousel-nav li .image-wrapper {
  border: 1px solid transparent;
}

.carousel .carousel-nav li .image-wrapper::before {
  padding-top: 56.25%; /* 16:9 */
}

main .carousel .carousel-nav li .image-wrapper img {
  width: 100%;
}

.carousel-nav li:hover .image-wrapper,
.carousel-nav li.is-active .image-wrapper {
  border-color: $primary-color;
}

.carousel-nav li:hover,
.carousel-nav li.is-active {
  font-weight: 400;
}

.carousel-nav figcaption {
  padding: 1em 0 0;
  text-align: center;
}

.carousel .caption {
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.carousel ::selection {
  background: none;
}
