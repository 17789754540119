/* Tryptichon Teaser
-------------------------------------------------- */
.tryptichon-teaser .carousel .wrap {
  position: relative;
  margin: 0 -1px;
}

.tryptichon-teaser .carousel .wrap > li {
  overflow: hidden;
  flex: 1 0 100%;
  padding: 0 1px;

  @media (min-width: 550px) {
    flex-basis: 50%;
  }

  @media (min-width: 850px) {
    flex-basis: 33.3333333333%;
  }
}

.tryptichon-teaser .carousel .wrap > li + li {
  margin-left: 1px;
}

@media (max-width: 849px) {
  .tryptichon-teaser .carousel {
   margin: 0 -($grid-gutter-width / 2);
  }

  .tryptichon-teaser .carousel .carousel-wrapper {
    padding: 0 50px;
  }
}

@media (min-width: 850px) {
  .tryptichon-teaser .carousel.hide-nav .carousel-buttons {
    display: none;
    position: absolute;
  }
}

.tryptichon-teaser .carousel-buttons button {
  top: calc(27% + 5vw);
  left: 0;
  height: 100px;
}

.tryptichon-teaser .carousel-buttons .next {
  left: auto;
  right: 0;
}

.tryptichon-teaser a {
  overflow: hidden;
  display: block;
  text-align: center;
  white-space: normal;
}

.tryptichon-teaser a:hover {
  color: $primary-color;
}

.tryptichon-teaser .image {
  position: relative;

  margin-bottom: $default-margin;
}

.tryptichon-teaser .image > div {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  transform: translateZ(0) scale(1);
  transition: transform .5s ease;
  backface-visibility: hidden;
}

.tryptichon-teaser a:hover .image > div {
  transform: scale(1.2);
  background-color: $primary-color;
  //background-blend-mode: multiply;
}

.tryptichon-teaser .h2-style {
  font-size: 2rem;
  margin: 1rem 0 .75rem;
  color: currentColor;
}


