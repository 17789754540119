/* Image Teaser
-------------------------------------------------- */

.image-teaser .image {
  @include breakpoint(md) {
    float: left;
    max-width: 840px;
  }
}

.image-teaser .text {
  background: $background-color;
  position: relative;
  margin: 0 $grid-outer-margin 0;
  padding-top: 50px;
  text-align: center;

  @media (min-width: 600px) {
    margin-top: -150px;
  }

  @include breakpoint(md) {
    padding-top: 100px;
    margin: -300px 0 0;
    float: right;
    max-width: 730px;
  }
}

.image-teaser .text.module-padding {
  /* Revert .module-padding */
  @media (max-width: 599px) {
    padding-right: 0;
    padding-left: 0;
  }
}

/* Image Teaser (right)
-------------------------------------------------- */

@include breakpoint(md) {
  .image-teaser-right .text {
    float: left;
  }

  .image-teaser-right .image {
    float: right;
  }
}
