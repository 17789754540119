/* Image Gallery
-------------------------------------------------- */

main > .image-gallery:first-child {
  margin-top: -$module-margin;
}

.image-gallery .carousel .wrap > * {
  text-align: center;
}

.image-gallery ul,
.image-gallery li,
.image-gallery li::before {
  content: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.image-gallery .carousel .image-wrapper {
  position: relative;
  overflow: hidden;
}

.image-gallery .carousel .image-wrapper::before {
  content: "";
  display: block;
  height: 0;
  padding-top: 100%;

  @include breakpoint(sm) {
    padding-top: 70%;
  };

  @include breakpoint(md) {
    padding-top: 41.7%;
  };
}

.image-gallery .carousel .image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  margin: 0;
  padding: 0;
  max-width: none;
  width: 180%;

  &.loaded {
    height: auto;
  }

  @include breakpoint(sm) {
    width: calc(200% - 70%);
  };

  @include breakpoint(md) {
    width: 100%;
  };
}

.image-gallery .carousel-wrapper figcaption {
  background: rgba(0, 0, 0, .55);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 25px 0 calc(60px + 2vw);
  font-size: 1rem;
  color: #fff;
  text-align: center;

  @media (min-width: 500px) {
    padding-bottom: calc(80px + 2vw);
  };

  @media (min-width: 600px) {
    padding-bottom: 100px;
  };
}
