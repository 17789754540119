// Config
@import 'variables';

// Base
@import 'base/reset';
@import 'base/fonts';
@import 'base/icon';
@import 'base/grid';
@import 'base/aspect-ratio';
@import 'base/modules';
@import 'base/typography';
@import 'base/media';
@import 'base/form';
@import 'base/carousel';

/* Document
-------------------------------------------------- */

html {
  background: $background-color;
  position: relative;
  overflow-y: scroll;
  min-height: 100%;
  color: $color;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 300;
  font-style: normal;
  line-height: $line-height;
  cursor: default;

  /* Disable tap highlighting */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  min-height: 100vh;
}

/* Misc
-------------------------------------------------- */

hr {
  margin-top: $default-margin;
  margin-bottom: $default-margin;
  color: currentColor;
  border-top: 2px solid;
  opacity: .25;
}

.seperator::before,
.seperator-wide::before {
  content: "";
  background: $primary-color;
  width: 200px;
  height: 2px;
  display: block;
  margin: 0 auto ($default-margin - .2rem);
}

h3.seperator::before,
h3.seperator-wide::before {
  margin-bottom: .75em;
}

.seperator-wide::before {
  width: 300px;
}



/* Main
-------------------------------------------------- */

main {
  min-height: 400px;
  margin: $module-margin 0 0;
}

// Elements
@import 'elements/button';
@import 'elements/filter';
@import 'elements/product-teaser';

// Modules
@import 'header/header';
@import 'stage/stage';
@import 'link-list/link-list';
@import 'anchor-navigation/anchor-navigation';
@import 'image-gallery/image-gallery';
@import 'content-1-column/content-1-column';
@import 'content-2-columns/content-2-columns';
@import 'page-item/page-item';
@import 'image-text/image-text';
@import 'client-quote/client-quote';
@import 'get-a-quote/get-a-quote';
@import 'image-teaser/image-teaser';
@import 'tryptichon-teaser/tryptichon-teaser';
@import 'contact-teaser/contact-teaser';
@import 'global-teaser-bar/global-teaser-bar';
@import 'footer/footer';

// Base misc
@import 'base/keyframes';
@import 'base/helper';
