/* Link List
-------------------------------------------------- */

.link-list li + li {
  margin-top: 1.5em;
}

.link-list a {
  position: relative;
  display: block;
  padding: 1.6rem 10vw 1.4rem 5vw;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: .1em;
  text-transform: uppercase;
  border: 1px solid currentColor;
  hyphens: auto;
  word-break: break-word;

  @include breakpoint(md) {
    padding-right: 5.5rem;
    padding-left: 4rem;
  }
}

.link-list a:hover {
  border-color: $primary-color;
  color: $color;
}

.link-list a .icon {
  position: absolute;
  top: 50%;
  right: 5vw;
  width: auto;
  height: 2.25rem;
  transform: translateY(-50%);

  @include breakpoint(md) {
    right: 4.25rem;
  }
}
