/* Page Item
-------------------------------------------------- */

.page-item a {
  text-align: center;
  color: $color;
}

.page-item li::before {
  content: none;
}

.page-item .image {
  margin-bottom: $default-margin;
}

.page-item .image img {
  display: inline-block;
  max-width: 160px;
  width: 100%;
}

/* Page Item Carousel
-------------------------------------------------- */

.page-item .carousel {
  margin: 0 -($grid-gutter-width / 2);
}

.page-item .carousel-wrapper {
  padding: 0 50px;
}

.page-item .carousel .wrap > li {
  flex: 1 0 50%;
  padding: 0 ($grid-gutter-width / 2);

  @include breakpoint(md) {
    flex-basis: 25%;
  }
}

.page-item .carousel-buttons button {
  left: 0;
  height: 100px;

  @media (min-width: 1320px) {
    left: $grid-outer-margin;
  }
}

.page-item .carousel-buttons .next {
  left: auto;
  right: 0;

  @media (min-width: 1320px) {
    right: $grid-outer-margin;
  }
}
