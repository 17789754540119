/* Multiple Columns
-------------------------------------------------- */

.content-2-columns .text {
  column-count: 1;
  column-gap: ($grid-gutter-width * 2);

  @include breakpoint(md) {
    column-count: 2;
  }
}

.content-2-columns:not([data-child-elements="1"]) .text > * {
  @include breakpoint(md) {
    break-inside: avoid;
  }
}
