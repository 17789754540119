html {
  /* Prevent adjustments of font size after orientation
     changes in IE and iOS. */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-size-adjust: none;
}

/* Rajdhani
-------------------------------------------------- */

@font-face {
  font-family: 'Rajdhani';
  src: url('/fonts/rajdhani-light-webfont.eot');
  src: url('/fonts/rajdhani-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/rajdhani-light-webfont.woff2') format('woff2'),
       url('/fonts/rajdhani-light-webfont.woff') format('woff'),
       url('/fonts/rajdhani-light-webfont.ttf') format('truetype'),
       url('/fonts/rajdhani-light-webfont.svg#rajdhanilight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rajdhani';
  src: url('/fonts/rajdhani-medium-webfont.eot');
  src: url('/fonts/rajdhani-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/rajdhani-medium-webfont.woff2') format('woff2'),
       url('/fonts/rajdhani-medium-webfont.woff') format('woff'),
       url('/fonts/rajdhani-medium-webfont.ttf') format('truetype'),
       url('/fonts/rajdhani-medium-webfont.svg#rajdhanimedium') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rajdhani';
  src: url('/fonts/rajdhani-bold-webfont.eot');
  src: url('/fonts/rajdhani-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/rajdhani-bold-webfont.woff2') format('woff2'),
       url('/fonts/rajdhani-bold-webfont.woff') format('woff'),
       url('/fonts/rajdhani-bold-webfont.ttf') format('truetype'),
       url('/fonts/rajdhani-bold-webfont.svg#rajdhanibold') format('svg');
  font-weight: 700;
  font-style: normal;
}

/* Rokkitt
-------------------------------------------------- */

@font-face {
  font-family: 'Rokkitt';
  src: url('/fonts/rokkitt-light-webfont.eot');
  src: url('/fonts/rokkitt-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/rokkitt-light-webfont.woff2') format('woff2'),
       url('/fonts/rokkitt-light-webfont.woff') format('woff'),
       url('/fonts/rokkitt-light-webfont.ttf') format('truetype'),
       url('/fonts/rokkitt-light-webfont.svg#rokkittlight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rokkitt';
  src: url('/fonts/rokkitt-medium-webfont.eot');
  src: url('/fonts/rokkitt-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/rokkitt-medium-webfont.woff2') format('woff2'),
       url('/fonts/rokkitt-medium-webfont.woff') format('woff'),
       url('/fonts/rokkitt-medium-webfont.ttf') format('truetype'),
       url('/fonts/rokkitt-medium-webfont.svg#rokkittmedium') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rokkitt';
  src: url('/fonts/rokkitt-bold-webfont.eot');
  src: url('/fonts/rokkitt-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/rokkitt-bold-webfont.woff2') format('woff2'),
       url('/fonts/rokkitt-bold-webfont.woff') format('woff'),
       url('/fonts/rokkitt-bold-webfont.ttf') format('truetype'),
       url('/fonts/rokkitt-bold-webfont.svg#rokkittbold') format('svg');
  font-weight: 700;
  font-style: normal;
}
