/* Image Text
-------------------------------------------------- */

.image-text [class^="col"] {
  display: flex;
  flex-direction: column;
}

.image-text .image,
.image-text .headline {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-text [class^="col"] > .image {
  display: none;
  z-index: -1;
}

.image-text .image {
  margin-bottom: $default-margin;
}

@include breakpoint(md) {
  .image-text [class^="col"] {
    flex-direction: row;
  }

  .image-text [class^="col"] > .image {
    display: block;
    z-index: auto;
  }

  .image-text .text-group .image {
    display: none;
    z-index: -1;
  }

  .image-text .image {
    flex: 1;
    padding-right: ($grid-gutter-width / 2);
  }

  .image-text .text-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: ($grid-gutter-width / 2);
  }

  .image-text .headline {

    flex-grow: 0;
  }
}
.image-text ul li {
  letter-spacing: .06em;
  margin-bottom: $line-height;

}

.image-text h2.seperator {
  margin-left: -2px;
}
/* Image Text (right)
-------------------------------------------------- */

@include breakpoint(md) {
  .image-text-right .image {
    padding-right: 0;
    padding-left: ($grid-gutter-width / 2);
  }

  .image-text-right .text-group {
    padding-right: ($grid-gutter-width / 2);
    padding-left: 0;
    order: -1;
  }
}
