@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

#footer {
  background: $navigation-color;
  margin-top: $module-margin;
  padding: $module-padding;
  color: #fff;

  .logo {
    display: inline-block;
    border: none;
    margin: $default-margin auto;
      @include breakpoint(md) {
        margin: 0;
        margin-top: $default-margin;
      }

     img {
      width: 153px;
      max-width: none;
      height: 100%;
    }
  }

  .button {
    margin-top: $default-margin;
    margin-bottom: 0;
    align-self: stretch;
  }
}

.footer-wrapper , .footer-link , .signature{
  display: flex;
  flex-direction: column;
  text-align: center;

  @include breakpoint(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }
}
.footer-link {
  /*-- Line drawing --*/
    margin-bottom: $default-margin;
    border-bottom: 1px solid #545454;
    font-size: 25px;
    font-weight: 400;


  @include breakpoint(md) {
    margin-bottom: $default-margin;
    border-bottom: 1px solid #545454;
    font-size: inherit;
    text-align: left;
    align-items: flex-start;
    padding-bottom: 20px;
  }
}
.footer-wrapper{
    margin-bottom: $default-margin;
    font-size: 25px;
    font-weight: 400;

    nav#to-top a.tt {
    display: none;
    }

  @include breakpoint(md){
    margin-bottom: $default-margin;
    border-bottom: 1px solid #545454;
    font-size: inherit;

    nav#to-top a.tt {
      display: inline-block;
      position: relative;
    }

    nav#to-top a.tt:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

  }
}

.footer-wrapper li {
  display: inline-block;

  @include breakpoint(md){
    margin-bottom: 0;
    padding-right: $default-margin;
    margin-bottom: $default-margin;
  }
}

//only the first li will display in small screens
.footer-link nav li:not(.active){
  display: none;
  color: #939393;
  font-family: $font-family;

    @include breakpoint(md) {
      display: flex;
    }
}

//only 3 elements => the 4.element must not display
.footer-link nav:last-child {
  display: none;
  @include breakpoint(md){
    display: flex;
  }
}

.footer-link ul li.active {
  color: $primary-color;
  padding-bottom: 20px;

  @include breakpoint(md){
    padding-bottom: 8px;
  }
}


.footer-wrapper {
  ul#info {
    display: flex;
    flex-direction: column;
    //padding-bottom: $default-margin;
    margin-bottom: 20px;
    border-bottom:1px solid #545454;
    li {
      margin-bottom: 20px;
    }
    @include breakpoint(md){
      flex-direction: row;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  #social-nav{
    font-family: $font-family-secondary;
    font-size: 18px;
    padding-bottom: 5px;
    li {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  #to-top{
    margin-bottom: $default-margin;
  }
}


/*--Ordering in Mediun and Small size --*/

#footer .wrapper.wide{
  display: flex;
  flex-direction: column;

  .footer-link {
    order: 1;
  }
 .footer-wrapper {
   order: 2;
 }
  .signature {
    order: 3;
  }
  .button {
    order: 4;
  }
  @include breakpoint(md) {
  .footer-link {
     order: 2;
   }
    .footer-wrapper {
      order: 1;
    }
    .signature {
      order: 3;
    }
    .button {
      display: none;
    }
  }
}

//make SVG-Pic clickable
@media screen and (max-width: $screen-md) {
#footer .wrapper.wide a.button:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#footer .wrapper.wide a.button {
  position: relative;
  display: inline-block;
}

}
