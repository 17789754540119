/* Product Teaser
-------------------------------------------------- */

.product-teaser {
  background: $background-color;
  position: relative;
  overflow: hidden;
  display: block;
  height: 100%;
  border: 2px solid #939393;
  text-align: center;
}

.product-teaser:hover {
  color: inherit;
  border-bottom-width: 2px;
}

.product-teaser::after {
  content: "";
  background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0));
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: $module-padding-less * 1.75;
}

.product-teaser > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-teaser .text {
  margin: auto;
}

.product-teaser h3 {
  font-family: "Rajdhani", sans-serif;
  font-size: 35px;
  line-height: 1.2;
  font-weight: 300;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #000;
  margin: 1rem 0 .75rem;
}

.product-teaser:hover h2,
.product-teaser:hover h3 {
  color: $primary-color !important;
}

.product-teaser .meta {
  font-size: $font-size-less;
  font-weight: 300;
}

.product-teaser-more {
  border: 0;
}

/* Product Teaser (Item List Products)
-------------------------------------------------- */

.item-list-products {
  .product-teaser:not(.product-teaser-more) {
    background: #292929;
    border: 0;
    color: #fff;
  }

  .product-teaser:not(.product-teaser-more)::after {
    background: linear-gradient(0deg, rgba(41, 41, 41, 1), rgba(41, 41, 41, 1) 40%, rgba(41, 41, 41, 0));
  }

  .product-teaser h3 {
    color: #fff;
  }
}
