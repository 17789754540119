/* Get a quote
-------------------------------------------------- */

.get-a-quote-wrapper {
  overflow: hidden;
  border-top: 2px solid $primary-color;
  border-bottom: 2px solid $primary-color;
  padding: 1em;
  display: flex;
  flex-direction: column;

  @include breakpoint(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.get-a-quote .h2-style {
  font-size: 2rem;
  text-align: left;
  margin: 0 0 .5em;

  @include breakpoint(sm) {
    margin-top: .5em;
  }
}

.get-a-quote h3 {
  margin: 0;

  text-align: left;
}

.get-a-quote .quote {
  @include breakpoint(sm) {
    padding-left: 1em;
  }
}

.get-a-quote .price {
  color: $primary-color;
}

.get-a-quote .price b {
  font-family: "Rajdhani", sans-serif;
  font-size: 3em;
  line-height: 1.2;
  white-space: nowrap;
}

.get-a-quote .button {
  margin: .2em 0;
}
