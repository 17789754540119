// Breakpoints
$screen-xs: 479px !default;
$screen-sm: ($screen-xs + 1) !default;
$screen-md: 990px !default;
$screen-lg: 1280px !default;
$menu-breakpoint: $screen-md !default;

// Layout
$header-height: 63px !default;
$module-margin: 100px !default;
$module-padding: 40px !default;
$module-padding-less: 20px !default;
$default-margin: 1.75rem !default;

// Styles
$background-color: #fff !default;
$navigation-color: #292929 !default;
$background-color-input: #fff !default;
$border-color-input: #7B7B7A !default;
$color: #292929 !default;
$color-lighten: #C9C9C9 !default;
$text-link: #EDAF4A;
$primary-color: #EDAF4B !default;
$primary-color-hover: #bb862f !default;

// Typo
$font-family: "Rokkitt", Arial, sans-serif !default;
$font-family-secondary: "Rajdhani", Arial, sans-serif !default;
$font-size: 18px !default;
$font-size-less: 16px !default;
$line-height: 1.54 !default;
