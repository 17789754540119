/* Media
-------------------------------------------------- */

img {
  display: block;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
  -ms-interpolation-mode: bicubic;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

figure {
  margin: 0;
}

figure.video {
  width: 100%;
}

figure.video iframe,
figure.video object,
figure.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figcaption {
  background: $background-color;
  padding: .5rem $grid-gutter-width;
  font-size: $font-size-less;
  text-align: left;
}
